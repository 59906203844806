import React from 'react';
import GlassContainer from '../components/GlassContainer';
import './ServicePage.css';

const ConsultationService = () => {
  const description = "Dermaplaning is a manual exfoliation technique that gently removes the top layer of dead skin cells and fine vellus hair (peach fuzz) to reveal smoother, brighter skin underneath. It is an ideal treatment for those looking to improve skin texture and achieve a flawless makeup application. Dermaplaning also allows for better absorption of skincare products and can enhance the effects of other treatments like facials or chemical peels.";
  const priceList = [
    { service: 'Dermaplaning (60 minutes)', price: '$120' },
  ];

  return (
    <GlassContainer>
      <div className="service-page">
        <h1>Dermaplaning</h1>
        <p>{description}</p>
        <div className="price-list">
          <h2>Price List</h2>
          <ul>
            {priceList.map((item, index) => (
              <li key={index}>
                <strong>{item.service}</strong>: {item.price}
              </li>
            ))}
          </ul>
          <div className="text-center mt-4 appointment-button">
          <a href='/appointment' className="btn btn-primary">
            Book Your Appointment
          </a>
        </div>
        </div>
      </div>
    </GlassContainer>
  );
};

export default ConsultationService;
