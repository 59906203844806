import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import './Navbar.css'; // Add a separate CSS file for styling the Navbar
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import Instagram icon

function NavbarComponent() {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === '/';

  const handleScrollToSection = (section) => {
    if (!isHomePage) {
      navigate('/');
      setTimeout(() => {
        scroller.scrollTo(section, {
          smooth: true,
          duration: 500,
          offset: -70,
        });
      }, 100);
    } else {
      scroller.scrollTo(section, {
        smooth: true,
        duration: 500,
        offset: -70,
      });
    }
  };

  return (
    <>
      {/* Sticky Action Bar */}
      <div className="action-bar sticky-top">
        <Container className="d-flex justify-content-center">
          <a href="tel:+1 (250) 215-4930" className="action-link">
            <i className="fas fa-phone"></i> +1 (250) 215-4930
          </a>
          <a href="mailto:marzi@marskincare.ca" className="action-link ms-3">
            <i className="fas fa-envelope"></i> marzi@marskincare.ca
          </a>
          <a href="/appointment" className="action-link ms-3">
            <i className="fas fa-calendar-alt"></i> Book Appointment
          </a>
        </Container>
      </div>

      {/* Navigation Bar */}
      <Navbar bg="dark" variant="dark" expand="lg" className="navbar-custom">
        <Container>
          <Navbar.Brand as={Link} to="/">Skincare by Marzi</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={() => handleScrollToSection('about')}>About</Nav.Link>
              <Nav.Link onClick={() => handleScrollToSection('services')}>Services</Nav.Link>
              <Nav.Link onClick={() => handleScrollToSection('contact')}>Contact</Nav.Link>
              <Nav.Link className="apt" as={Link} to="/appointment">Appointments</Nav.Link>
            </Nav>
            {/* Instagram icon on the right */}
            <Nav className="ms-auto">
              <Nav.Link href="https://www.instagram.com/skincarebymarzi?igsh=cXFwcWQ1MnN2cDVk" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarComponent;
