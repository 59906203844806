import React from 'react';
import GlassContainer from '../components/GlassContainer';
import './ServicePage.css';

const ConsultationService = () => {
  const description = "Microneedling is a revolutionary treatment that stimulates collagen production by creating micro-injuries in the skin using tiny needles. This process enhances the skin's ability to regenerate itself, improving the appearance of fine lines, wrinkles, and scars. Our microneedling treatments are suitable for various skin types and can also address issues like enlarged pores and pigmentation. The result is firmer, smoother, and more youthful-looking skin. We also offer hair microneedling to stimulate hair growth and scalp health.";
  const priceList = [
    { service: 'Micro-needling Face and Neck (90 minutes)', price: '$350' },
    { service: 'Micro-needling Face and Hair (90 minutes)', price: '$250' },
    { service: 'Micro-needling Face and Body Part (90 minutes)', price: '$250' },

  ];

  return (
    <GlassContainer>
      <div className="service-page">
        <h1>Microneedling</h1>
        <p>{description}</p>
        <div className="price-list">
          <h2>Price List</h2>
          <ul>
            {priceList.map((item, index) => (
              <li key={index}>
                <strong>{item.service}</strong>: {item.price}
              </li>
            ))}
          </ul>
        </div>
        <div className="text-center mt-4 appointment-button">
          <a href='/appointment' className="btn btn-primary">
            Book Your Appointment
          </a>
        </div>
      </div>
    </GlassContainer>
  );
};

export default ConsultationService;
