import React from 'react';
import GlassContainer from '../components/GlassContainer';
import './ServicePage.css';

const ConsultationService = () => {
  const description = "At Skincare by Marzi, we understand that every individual's skin is unique. Our consultation services offer a thorough skin analysis, where our expert estheticians assess your skin type, concerns, and goals. We discuss your lifestyle, skincare routine, and any underlying conditions that might affect your skin's health. After the assessment, we recommend personalized treatments and skincare regimens that best suit your needs.";
  const priceList = [
    { service: 'In-person Consultation (15 minutes)', price: 'Free' },
    { service: 'Over the Phone Consultation (15 minutes)', price: 'Free' },
  ];

  return (
    <GlassContainer>
      <div className="service-page">
        <h1>Consultation</h1>
        <p>{description}</p>
        <div className="price-list">
          <h2>Price List</h2>
          <ul>
            {priceList.map((item, index) => (
              <li key={index}>
                <strong>{item.service}</strong>: {item.price}
              </li>            
            ))}
          </ul>
          <div className="text-center mt-4 appointment-button">
          <a href='/appointment' className="btn btn-primary">
            Book Your Appointment
          </a>
        </div>
        </div>
      </div>
    </GlassContainer>
  );
};

export default ConsultationService;
