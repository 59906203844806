import React from 'react';
import GlassContainer from '../components/GlassContainer';
import './ServicePage.css';

const ConsultationService = () => {
  const description = "Our facials are designed to revitalize your skin by deep cleansing, exfoliation, and hydration. We offer a variety of treatments catered to different skin types and concerns, from acne-prone skin to anti-aging needs. Using high-quality, professional-grade products, our facials provide an instant glow while promoting long-term skin health. Our estheticians customize each facial to address your unique needs, ensuring you leave with refreshed and rejuvenated skin.";
  const priceList = [
    { service: 'Expres Facial (60 minutes)', price: '$115' },
    { service: 'Acne Treatment Facial (90 minutes)', price: '$155' },
    { service: 'Classic Anti-aging (90 minutes)', price: '$155' },
    { service: 'Gold Anti-aging (120 minutes)', price: '$185' },

  ];

  return (
    <GlassContainer>
      <div className="service-page">
        <h1>Facials</h1>
        <p>{description}</p>
        <div className="price-list">
          <h2>Price List</h2>
          <ul>
            {priceList.map((item, index) => (
              <li key={index}>
                <strong>{item.service}</strong>: {item.price}
              </li>
            ))}
          </ul>
          <div className="text-center mt-4 appointment-button">
          <a href='/appointment' className="btn btn-primary">
            Book Your Appointment
          </a>
        </div>
        </div>
      </div>
    </GlassContainer>
  );
};

export default ConsultationService;
