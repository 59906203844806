import React from 'react';
import GlassContainer from '../components/GlassContainer';
import './ServicePage.css';

const ConsultationService = () => {
  const description = "Microdermabrasion is an advanced exfoliation technique designed to improve skin texture, tone, and clarity. Using a specialized tool, we gently remove the outermost layer of dead skin cells, revealing smoother, more radiant skin underneath. This treatment is ideal for reducing fine lines, acne scars, and uneven pigmentation, leaving your skin soft, smooth, and glowing. Regular sessions can help maintain a more youthful complexion by encouraging the growth of new, healthy skin cells.";
  const priceList = [
    { service: 'Micro-dermabrasion (60 minutes)', price: '$110' },
  ];

  return (
    <GlassContainer>
      <div className="service-page">
        <h1>Microdermabrasion</h1>
        <p>{description}</p>
        <div className="price-list">
          <h2>Price List</h2>
          <ul>
            {priceList.map((item, index) => (
              <li key={index}>
                <strong>{item.service}</strong>: {item.price}
              </li>
            ))}
          </ul>
          <div className="text-center mt-4 appointment-button">
          <a href='/appointment' className="btn btn-primary">
            Book Your Appointment
          </a>
        </div>
        </div>
      </div>
    </GlassContainer>
  );
};

export default ConsultationService;
